import React from 'react';
import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
// import LogoPng from '../../assets/logo_full_br.png'

import styles from './logo.module.scss';

const Logo = () => (
  <div className={styles.logo}>
    <LogoSvg className={styles.logo__logo} alt="Logo" />
    {/* <img src={LogoPng} className={styles.logo__logo} alt="Logo" /> */}
  </div>
);

export default Logo;

import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';

import styles from './clock.module.scss';

const Clock = () => (
  <div className={styles.clock}>
    <p className={styles.clock__time}>
      <Moment interval={1000} format="HH:mm" />
    </p>
    <p className={styles.clock__paragraph}>
      <Moment interval={1000} format="dddd, DD.MM.YYYY" />
    </p>
  </div>
);

export default Clock;
